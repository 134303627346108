<template>
  <div class="wrapper">
    <vxe-toolbar>
      <template v-slot:buttons>
        <vxe-button
          status="my-orange"
          @click="showSearch(false)"
          v-if="seachStatus"
          >隐藏查询</vxe-button
        >
        <vxe-button
          status="my-orange"
          icon="fa fa-search"
          @click="showSearch(true)"
          v-if="!seachStatus"
          >查询</vxe-button
        >
          <vxe-button status="my-orange" icon="fa fa-plus" @click="addDataBtn()"></vxe-button>
      </template>
    </vxe-toolbar>
    <div class="search" v-if="seachStatus">
      <vxe-form
        title-align="right"
        title-width="120"
        title-colon
        :data="searchData"
        @submit="searchEvent"
        @reset="searchReset"
      >
        <vxe-form-item title="用餐时间" span="8">
          <div class="datetime">
            <vxe-input
              v-model="searchData.from"
              type="datetime"
              clearable
            ></vxe-input>
            <span>~</span>
            <vxe-input
              v-model="searchData.to"
              type="datetime"
              clearable
            ></vxe-input>
          </div>
        </vxe-form-item>
        <vxe-form-item align="center" span="24">
          <vxe-button type="submit" status="my-orange" icon="fa fa-check"
            >应用</vxe-button
          >
          <vxe-button type="reset" status="my-orange" icon="fa fa-undo"
            >重置</vxe-button
          >
        </vxe-form-item>
      </vxe-form>
    </div>

    <div class="table">
      <vxe-grid
        border
        stripe
        resizable
        auto-resize
        :columns="tableColumn"
        :data="tableData"
        :loading="loading"
      >
        <template v-slot:leaveTime="{ row }">
          <span>{{ $tools.reversalTime(row.leaveTime) }}</span>
        </template>
        <template v-slot:factTime="{ row }">
          <span>{{ $tools.reversalTime(row.factTime) }}</span>
        </template>
      </vxe-grid>
    </div>
    <!-- 分页 -->
    <div class="page">
      <vxe-pager
        :current-page.sync="tablePage.currentPage"
        :page-size.sync="tablePage.pageSize"
        :page-sizes="tablePage.pageSizes"
        :total="tablePage.totalResult"
        :layouts="layouts"
        @page-change="pageChange"
      ></vxe-pager>
    </div>
    <div>
      <el-dialog title="自动添加数据" :visible.sync="dialogVisible" width="50%"  :before-close="handleClose">
       <el-form :label-position="labelPosition" label-width="auto" :model="formLabelAlign" :rules="rules" ref="ruleForm">
          <el-form-item label="开始时间"  prop="from">
              <el-date-picker type="datetime" v-model="formLabelAlign.from" placeholder="开始时间" value-format="yyyy-MM-dd HH:mm:ss" :disabled='ifTaskClose'  style="width:100%"></el-date-picker>
          </el-form-item>
          
          <el-form-item label="结束时间"  prop="to">
              <el-date-picker type="datetime" v-model="formLabelAlign.to" placeholder="结束时间" value-format="yyyy-MM-dd HH:mm:ss" :disabled='ifTaskClose' style="width:100%"></el-date-picker>
          </el-form-item>
          <el-form-item label="最小间隔时间(s)" prop="minIntervalSecond" >
            <el-input v-model="formLabelAlign.minIntervalSecond" :disabled='ifTaskClose' @input="(v)=>(formLabelAlign.minIntervalSecond=v.replace(/[^\d]/g,''))" ></el-input>
          </el-form-item>
          <el-form-item label="最大间隔时间(s)"  prop="maxIntervalSecond">
            <el-input v-model="formLabelAlign.maxIntervalSecond" :disabled='ifTaskClose' @input="(v)=>(formLabelAlign.maxIntervalSecond=v.replace(/[^\d]/g,''))" ></el-input>
          </el-form-item>
           <el-form-item label="消费金额(元)"  prop="customAmount">
            <el-input v-model="formLabelAlign.customAmount" :disabled='ifTaskClose' @input="(v)=>(formLabelAlign.customAmount=v.replace(/[^\d.]/g,''))" ></el-input>
          </el-form-item>
           <el-form-item label="生成条数上限"  prop="generateTotal">
            <el-input v-model="formLabelAlign.generateTotal" :disabled='ifTaskClose' @input="(v)=>(formLabelAlign.generateTotal=v.replace(/[^\d]/g,''))" ></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click='handleClose()'>取 消</el-button>
          <el-button type="primary" @click="dialogSure('ruleForm')">确 定</el-button>
          <el-button type="primary" v-if="ifTaskClose" @click="closeTask()">关闭任务</el-button>

        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
export default {
  data() {
    return {
      loading: false,
      seachStatus: false,
      searchData: {},
      // 分页的参数配置
      tablePage: {
        totalResult: 0,
        currentPage: 1,
        pageSize: 10,
        align: "left",
        pageSizes: [10, 20, 50, 100, 500, 1000],
        perfect: true,
      },

      formLabelAlign:{},
      dialogVisible:false,
      tableColumn: [
        {
          type: "seq",
          title: "序号",
          width: 50,
          align: "center",
        },
        {
          field: "flowNo",
          title: "流水号",
          showOverflow: true,
          align: "center",
          minWidth: "100",
        },
        {
          field: "pubOldPersonName",
          title: "服务对象",
          showOverflow: true,
          align: "center",
          minWidth: "100",
        },
        {
          field: "amont",
          title: "刷卡金额",
          showOverflow: true,
          align: "center",
          minWidth: "100",
        },
        {
          field: "transTime",
          title: "就餐时间",
          showOverflow: true,
          align: "center",
          minWidth: "120",
        },
        {
          field: "transTypeName",
          title: "用餐类型",
          showOverflow: true,
          align: "center",
          minWidth: "120",
        },
        {
          field: "physicalCardNo",
          title: "饭卡编号",
          showOverflow: true,
          align: "center",
          minWidth: "120",
        },


      ],
      tableData: [],
      ifTaskClose:false,
      labelPosition: 'right',
        rules: {
          from: [
            { required: true, message: '请输入开始时间', trigger: 'blur' },
          ],
          to: [
            { required: true, message: '请输入结束时间', trigger: 'blur' }
          ],
          customAmount:[
            { required: true, message: '请输入消费金额', trigger: 'blur' }
          ],
          generateTotal:[
            { required: true, message: '请输入生成条数上限', trigger: 'blur' }
          ],
        
        },
    };
  },
  computed: {
    ...mapState(["layouts"]),
    ...mapGetters(["seqId"]),
  },
  watch: {
    seqId() {
      // this.getLists()
    },
  },
  created() {
    this.initView();
  },
  methods: {
    ...mapActions(["mealRecord", "delorgInfo","mealOrgPageListInfo","queryDataTask",'addDataTask','closeDataTask']),
    initView() {
      // this.getTeamList();
      // this.getCategoryList();
      this.getLists();
    },
    // 是否显示查询功能
    showSearch(bool) {
      this.seachStatus = bool;
    },
    goStaff(type, itemData) {
      this.$router.push({
        name: "addStaff",
        params: {
          type: type,
          id: type == "add" ? "add" : itemData.id,
        },
        query: {
          item: type == "add" ? "add" : JSON.stringify(itemData),
        },
      });
    },
    goAged(type, itemData) {
      this.$router.push({
        name: "addAged",
        params: {
          type: type,
          id: type == "add" ? "add" : itemData.id,
        },
        query: {
          item: type == "add" ? "add" : JSON.stringify(itemData),
        },
      });
    },
    goPage(type, itemData) {
      this.$router.push({
        name: "addManage",
        params: {
          type: type,
          id: type == "add" ? "add" : itemData.id,
        },
        query: {
          item: type == "add" ? "add" : JSON.stringify(itemData),
        },
      });
    },
    getLists() {
      this.loading = true;
      this.mealOrgPageListInfo({
        current: this.tablePage.currentPage,
        size: this.tablePage.pageSize,
        param: {
          orgName: this.searchData.orgName,
        },
      }).then((res) => {
        if (res.code == 200) {
          this.tableData = res.data.records;
          this.tablePage.totalResult = +res.data.total;
        }
        this.loading = false;
      });
    },
    // 分页功能
    pageChange(item) {
      if (item.type == "size") {
        this.tablePage.currentPage = 1;
      } else {
        this.tablePage.currentPage = item.currentPage;
      }
      this.getLists();
    },
    searchEvent() {
      console.log(this.searchData);
      this.seachStatus = false;
      this.getLists();
    },
    searchReset() {
      this.searchData = {};
      this.getLists();
    },
    remove(id) {
      this.$XModal.confirm("您确定要删除该数据?").then((type) => {
        if (type === "confirm") {
          console.log("执行删除操作");
          this.delete(id);
        }
      });
    },
    delete(id) {
      this.delorgInfo({
        id: id,
      }).then((res) => {
        if (res.code == 200) {
          this.$XModal.message({
            message: "删除成功",
            status: "success",
          });
          this.getLists();
        }
        this.loading = false;
      });
    },
    //自动添加数据按钮
    addDataBtn(){
      this.dialogVisible = true
      this.queryTask()
    },
    /**
     * 查询是否自动生成数据
     */
    queryTask(){
      this.queryDataTask().then(res=>{
        console.log(res);
        if(res.code==200){
          if(res.data){
          this.ifTaskClose=true
          this.formLabelAlign = res.data
        }else{
          this.ifTaskClose=false
        }
        }

      }).catch(err=>{
        console.log(err);
      })
    },
     //dialog确定
     dialogSure(formName){
       this.$refs[formName].validate((valid) => {

          if (valid) {
            if(Number(this.formLabelAlign.maxIntervalSecond)<Number(this.formLabelAlign.minIntervalSecond)){
              this.$message.error('最大时间间隔不可小于最小时间间隔')
              return
            }
            this.formLabelAlign.customAmount =  Number(this.formLabelAlign.customAmount)
            // console.log(this.formLabelAlign.customAmount);
            let  param = this.formLabelAlign
            this.addDataTask(param).then(res=>{
              console.log(res);
              if(res.code==200){
                this.$message.success('添加成功')
                this.ifTaskClose = false
                this.dialogVisible = false
              }
            }).catch(err=>{
              console.log(err);
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      //关闭任务
      closeTask(){
        this.closeDataTask().then(res=>{
          if(res.code==200){
            this.$message.success('关闭任务成功')
            this.dialogVisible = false
            this.formLabelAlign={}

          }
        }).catch(err=>{

        })
      },
    //关闭dialog
    handleClose(){
      this.formLabelAlign.from = ''
      this.formLabelAlign.to = ''
      this.formLabelAlign.maxIntervalSecond = ''
      this.formLabelAlign.minIntervalSecond = ''
      this.formLabelAlign.customAmount = ''
      this.formLabelAlign.generateTotal = ''

      this.dialogVisible = false

      
    }
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  .datetime {
    display: flex;
    align-items: center;
    span {
      margin: 0 3px;
    }
  }
}
.sign i {
  margin-right: 3px;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
</style>
